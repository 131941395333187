<template>
    <div>
        <animViewer class="errorImage center" :animation="{'anim_data' : lottieErrorReaded}" :loop="false"/>
        <h1>{{$t('Error_page.headline')}}</h1>
        <h2 v-if="message != null">{{message}}</h2>
        <p class="button center backButton" button-type=secondary @click="backToHome()">{{$t("Back")}}</p>
    </div>
</template>

<script>

import lottieError from '../assets/lotties/error.json'

//COMPONENTS
import animViewer from '../components/Object/animationViewer.vue'

export default {
    components : {
        animViewer
    },
    computed : {
        lottieErrorReaded (){
            return JSON.parse(JSON.stringify(lottieError))
        },
        message(){
            if (this.$route.query && this.$route.query.message){
                return this.$route.query.message
            } else if (this.$route.query && this.$route.query.error){
                return this.$route.query.error
            } else {
                return null
            }
        }
    },
    methods : {
        backToHome(){
            this.$router.push('/')
        }
    }
}
</script>


<style scoped>

    h1{
        margin-top: 20px;
        text-align: center;
    }

    h2{
        margin-top: 30px;
        text-align: center;
    }

    .errorImage{
        width: 50px;
        margin-top: 120px;
    }

    .backButton{
        margin-top: 30px;
        width: 160px;
        display: block;
    }
</style>